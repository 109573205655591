import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  text-transform: uppercase;
  width: 100%;
  h2 {
    text-align: center;
    letter-spacing: 7px;
    .title {
      color: ${({ theme }) => theme.colors.mediumRedViolet};
    }
    span {
      display: block;
    }
  }
`;

const Title = ({ title, subtitle, className }) => {
  return (
    <TitleWrapper className={className}>
      <h2>
        <span className="title">{title}</span>
        <span>{subtitle}</span>
      </h2>
    </TitleWrapper>
  );
};

export default Title;
