import { css } from 'styled-components';

// MEDIA QUERY MANAGER
/*
Note: in media query 1em = 16px (and not 10px)
600px = 37.5em: Phone //600px
900px - 56.25em: Tablet Portrait //900px
1200px - 75em: Tablet Landscape //1200px
[1200px - 1800px] is where or normal styles apply
1800px + - 112.5em : Big desktop //1800px
*/

// Break point argument choices:
export const sizes = {
  bigDesktop: 112.5,
  tabLand: 75,
  tabPort: 56.25,
  phone: 37.5,
};

export const above = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const minMaxWidth = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}em and (min-width: ${sizes[label]}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const below = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
